import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import { CookiesPoup } from "./homepage";

export default function ResponsibleDisclosure() {
  return (
    <>
      <TitleAndMetaTags
        title="Responsible Disclosure | Workativ"
        description="Workativ understands that protection of customer data is a significant responsibility and requires the highest priority."
        keywords={["workativ terms and conditions"]}
        ogTitle="Responsible Disclosure | Workativ"
        ogDescription="Workativ understands that protection of customer data is a significant responsibility and requires the highest priority."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <Content />
        </Layout>
      </Container>
    </>
  ); 
}

function Content() {
  return (
    <section className="w-100 float-left mt-4">
      <div className="container">
        <div className="w-100 float-left policy_div_prnt">
          <h1 className="font-section-sub-header">Policy</h1>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ understands that protection of customer data is a
            significant responsibility and requires the highest priority. We
            genuinely value the assistance of security researchers and any
            others in the security community to assist in keeping our systems
            secure. The responsible disclosure of security vulnerabilities helps
            us ensure the security and privacy of all our users.
          </p>
          <ul className="w-100 float-left font-section-normal-text-testimonials">
            <li>
              Reach out to security@workativ.com, if you have found any
              potential vulnerability in our products meeting the criteria
              mentioned in the policy below.
            </li>
            <li>
              You can expect an acknowledgment from our security team in about
              24 hours of submission.
            </li>
            <li>
              Workativ will define the severity of the issue based on the impact
              and the ease of exploitation.
            </li>
            <li>We may take 3 to 5 days to validate the reported issue.</li>
            <li>
              Actions will be initiated to fix the vulnerability in accordance
              with our commitment to security and privacy. We will notify you
              when the issue is fixed
            </li>
            <li>
              When conducting security testing, should not violate our policies,
              modify/delete unauthenticated user data, disrupt production
              servers, or to degrade user experience.
            </li>
            <li>Perform research only within the scope set out below;</li>
            <li>
              Use the identified communication channel, viz.,
              <a href="mailto:security@workativ.com">
                security@workativ.com
              </a>{" "}
              to report the vulnerability information to us; Documenting or
              publishing the vulnerability details in public domain is against
              our responsible disclosure policy; and
            </li>
            <li>
              Keep information about any vulnerability confidential until the
              issue is resolved
            </li>
          </ul>
        </div>
        <div className="w-100 float-left policy_div_prnt">
          <h2 className="font-section-sub-header">Reporting Guidelines</h2>
          <p className="font-section-normal-text-testimonials">
            Reporting Guidelines Please provide the following details on the
            report
          </p>
          <ul className="w-100 float-left font-section-normal-text-testimonials">
            <li>Description and potential impact of the vulnerability;</li>
            <li>
              A detailed description of the steps required to reproduce the
              vulnerability; and,
            </li>
            <li>Where available, a video POC.</li>
            <li>
              Your preferred name/handle for recognition in our Security
              Researcher Hall of Fame
            </li>
          </ul>
        </div>
        <div className="float-left w-100 policy_div_prnt">
          <h2 className="font-section-sub-header">Domains in Scope</h2>
          <ul className="font-section-normal-text-testimonials w-100 float-left">
            <li>*.workativ.com</li>
          </ul>
        </div>
        <div className="float-left w-100 policy_div_prnt">
          <h2 className="font-section-sub-header">Qualifying Bugs</h2>
          <ul className="font-section-normal-text-testimonials w-100 float-left">
            <li>Remote code execution (RCE)</li>
            <li>SQL/XXE Injection and command injection</li>
            <li>Cross-Site Scripting (XSS)</li>
            <li>Server side request forgery (SSRF)</li>
            <li>Misconfiguration issues on servers and application</li>
            <li>Authentication and Authorization related issues</li>
            <li>Cross site request forgeries (CSRF)</li>
          </ul>
        </div>
        <div className="float-left w-100 policy_div_prnt">
          <h2 className="font-section-sub-header">Non-Qualified Bugs</h2>
          <ul className="font-section-normal-text-testimonials w-100 float-left">
            <li>Html injection and Self-XSS</li>
            <li>Host header and banner grabbing issues</li>
            <li>
              Automated tool scan reports. Example: Web, SSL/TLS scan, Nmap scan
              results, etc.
            </li>
            <li>
              Missing HTTP security headers and cookie flags on insensitive
              cookies
            </li>
            <li>Rate limiting, brute force attack</li>
            <li>Login/logout CSRF</li>
            <li>Session timeout</li>
            <li>Unrestricted file upload</li>
            <li>Open redirections</li>
            <li>Formula/CSV Injection</li>
            <li>
              Denial of Service (DoS)/Distributed/ Denial of Service (DDoS)
            </li>
            <li>
              Vulnerabilities that require physical access to the victim
              machine.
            </li>
            <li>User enumeration such as User email, User ID, etc.</li>
            <li>
              Phishing / Spam (including issues related to SPF/DKIM/DMARC)
            </li>
            <li>Vulnerabilities found in third-party services</li>
            <li>EXIF data not stripped on images</li>
          </ul>
        </div>
        <div className="float-left w-100 policy_div_prnt">
          <h2 className="font-section-sub-header">Hall of Fame</h2>
          <p className="font-section-normal-text-testimonials line-height-18">
            While Workativ does not provide any reward for responsibly
            disclosing unique vulnerabilities and working with us to remediate
            them, we would like to publicly convey our deepest gratitude to the
            security researchers. We will add your name to our Hall of Fame.
            Your legendary efforts are truly appreciated by Workativ.
          </p>
          <p className="line-height-18 font-section-normal-text-testimonials">
            We would like to recognize the efforts of the following individuals
            for their contribution to our responsible disclosure program. Please
            accept our sincerest gratitude to every one of you.
          </p>
        </div>
      </div>
    </section>
  );
}
